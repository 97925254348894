import { ErrorHandler } from '@angular/core';
import { environment } from '../../environments/environment';
var Logger = /** @class */ (function () {
    function Logger(errorHandler) {
        this.errorHandler = errorHandler;
    }
    Logger.prototype.log = function (value) {
        var rest = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            rest[_i - 1] = arguments[_i];
        }
        if (!environment.production) {
            console.log.apply(console, [value].concat(rest));
        }
    };
    Logger.prototype.error = function (error) {
        this.errorHandler.handleError(error);
    };
    Logger.prototype.warn = function (value) {
        var rest = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            rest[_i - 1] = arguments[_i];
        }
        console.warn.apply(console, [value].concat(rest));
    };
    return Logger;
}());
export { Logger };
