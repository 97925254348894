/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./dt.component";
var styles_DtComponent = [];
var RenderType_DtComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DtComponent, data: {} });
export { RenderType_DtComponent as RenderType_DtComponent };
function View_DtComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, [[1, 0], ["dt", 1]], null, 0, "textarea", [["cols", "80"], ["rows", "10"]], [[8, "value", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dtextSet() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Show change"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 2, 0, currVal_0); }); }
export function View_DtComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { dt: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DtComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.on; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DtComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aio-dt", [], null, null, null, View_DtComponent_0, RenderType_DtComponent)), i0.ɵdid(1, 49152, null, 0, i2.DtComponent, [], null, null)], null, null); }
var DtComponentNgFactory = i0.ɵccf("aio-dt", i2.DtComponent, View_DtComponent_Host_0, { on: "on", doc: "doc" }, { docChange: "docChange" }, []);
export { DtComponentNgFactory as DtComponentNgFactory };
