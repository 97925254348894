/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./lazy-custom-element.component";
import * as i2 from "./elements-loader";
import * as i3 from "../shared/logger.service";
var styles_LazyCustomElementComponent = [];
var RenderType_LazyCustomElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LazyCustomElementComponent, data: {} });
export { RenderType_LazyCustomElementComponent as RenderType_LazyCustomElementComponent };
export function View_LazyCustomElementComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_LazyCustomElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aio-lazy-ce", [], null, null, null, View_LazyCustomElementComponent_0, RenderType_LazyCustomElementComponent)), i0.ɵdid(1, 114688, null, 0, i1.LazyCustomElementComponent, [i0.ElementRef, i2.ElementsLoader, i3.Logger], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LazyCustomElementComponentNgFactory = i0.ɵccf("aio-lazy-ce", i1.LazyCustomElementComponent, View_LazyCustomElementComponent_Host_0, { selector: "selector" }, {}, []);
export { LazyCustomElementComponentNgFactory as LazyCustomElementComponentNgFactory };
