import { OnChanges } from '@angular/core';
var NavItemComponent = /** @class */ (function () {
    function NavItemComponent() {
        this.isWide = false;
        this.level = 1;
        this.isParentExpanded = true;
        this.isExpanded = false;
        this.isSelected = false;
    }
    NavItemComponent.prototype.ngOnChanges = function () {
        this.nodeChildren = this.node && this.node.children ? this.node.children.filter(function (n) { return !n.hidden; }) : [];
        if (this.selectedNodes) {
            var ix = this.selectedNodes.indexOf(this.node);
            this.isSelected = ix !== -1; // this node is the selected node or its ancestor
            this.isExpanded = this.isParentExpanded &&
                (this.isSelected || // expand if selected or ...
                    // preserve expanded state when display is wide; collapse in mobile.
                    (this.isWide && this.isExpanded));
        }
        else {
            this.isSelected = false;
        }
        this.setClasses();
    };
    NavItemComponent.prototype.setClasses = function () {
        var _a;
        this.classes = (_a = {},
            _a['level-' + this.level] = true,
            _a.collapsed = !this.isExpanded,
            _a.expanded = this.isExpanded,
            _a.selected = this.isSelected,
            _a);
    };
    NavItemComponent.prototype.headerClicked = function () {
        this.isExpanded = !this.isExpanded;
        this.setClasses();
    };
    return NavItemComponent;
}());
export { NavItemComponent };
