import { environment } from '../../environments/environment';
var GaService = /** @class */ (function () {
    function GaService(window) {
        this.window = window;
        this.ga('create', environment['gaId'], 'auto');
    }
    GaService.prototype.locationChanged = function (url) {
        this.sendPage(url);
    };
    GaService.prototype.sendPage = function (url) {
        // Won't re-send if the url hasn't changed.
        if (url === this.previousUrl) {
            return;
        }
        this.previousUrl = url;
        this.ga('set', 'page', '/' + url);
        this.ga('send', 'pageview');
    };
    GaService.prototype.sendEvent = function (source, action, label, value) {
        this.ga('send', 'event', source, action, label, value);
    };
    GaService.prototype.ga = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var gaFn = this.window['ga'];
        if (gaFn) {
            gaFn.apply(void 0, args);
        }
    };
    return GaService;
}());
export { GaService };
