import { EventEmitter, OnInit } from '@angular/core';
var LOCAL_STORAGE_NAMESPACE = 'aio-notification/';
var NotificationComponent = /** @class */ (function () {
    function NotificationComponent(window, currentDate) {
        this.window = window;
        this.currentDate = currentDate;
        this.dismissed = new EventEmitter();
    }
    Object.defineProperty(NotificationComponent.prototype, "localStorage", {
        get: function () { return this.window.localStorage; },
        enumerable: true,
        configurable: true
    });
    NotificationComponent.prototype.ngOnInit = function () {
        var previouslyHidden = this.localStorage.getItem(LOCAL_STORAGE_NAMESPACE + this.notificationId) === 'hide';
        var expired = this.currentDate > new Date(this.expirationDate);
        this.showNotification = previouslyHidden || expired ? 'hide' : 'show';
    };
    NotificationComponent.prototype.contentClick = function () {
        if (this.dismissOnContentClick) {
            this.dismiss();
        }
    };
    NotificationComponent.prototype.dismiss = function () {
        this.localStorage.setItem(LOCAL_STORAGE_NAMESPACE + this.notificationId, 'hide');
        this.showNotification = 'hide';
        this.dismissed.next();
    };
    return NotificationComponent;
}());
export { NotificationComponent };
