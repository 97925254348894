var NavMenuComponent = /** @class */ (function () {
    function NavMenuComponent() {
        this.isWide = false;
    }
    Object.defineProperty(NavMenuComponent.prototype, "filteredNodes", {
        get: function () { return this.nodes ? this.nodes.filter(function (n) { return !n.hidden; }) : []; },
        enumerable: true,
        configurable: true
    });
    return NavMenuComponent;
}());
export { NavMenuComponent };
