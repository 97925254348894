/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./doc-viewer.component";
import * as i2 from "../../shared/logger.service";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../shared/toc.service";
import * as i5 from "../../custom-elements/elements-loader";
var styles_DocViewerComponent = [];
var RenderType_DocViewerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DocViewerComponent, data: {} });
export { RenderType_DocViewerComponent as RenderType_DocViewerComponent };
export function View_DocViewerComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_DocViewerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aio-doc-viewer", [], null, null, null, View_DocViewerComponent_0, RenderType_DocViewerComponent)), i0.ɵdid(1, 180224, null, 0, i1.DocViewerComponent, [i0.ElementRef, i2.Logger, i3.Title, i3.Meta, i4.TocService, i5.ElementsLoader], null, null)], null, null); }
var DocViewerComponentNgFactory = i0.ɵccf("aio-doc-viewer", i1.DocViewerComponent, View_DocViewerComponent_Host_0, { doc: "doc" }, { docReady: "docReady", docRemoved: "docRemoved", docInserted: "docInserted", docRendered: "docRendered" }, []);
export { DocViewerComponentNgFactory as DocViewerComponentNgFactory };
