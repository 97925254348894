import { OnInit, ElementRef, EventEmitter } from '@angular/core';
import { LocationService } from 'app/shared/location.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
/**
 * This component provides a text box to type a search query that will be sent to the SearchService.
 *
 * When you arrive at a page containing this component, it will retrieve the `query` from the browser
 * address bar. If there is a query then this will be made.
 *
 * Focussing on the input box will resend whatever query is there. This can be useful if the search
 * results had been hidden for some reason.
 *
 */
var SearchBoxComponent = /** @class */ (function () {
    function SearchBoxComponent(locationService) {
        this.locationService = locationService;
        this.searchDebounce = 300;
        this.searchSubject = new Subject();
        this.onSearch = this.searchSubject.pipe(distinctUntilChanged(), debounceTime(this.searchDebounce));
        this.onFocus = new EventEmitter();
    }
    /**
     * When we first show this search box we trigger a search if there is a search query in the URL
     */
    SearchBoxComponent.prototype.ngOnInit = function () {
        var query = this.locationService.search()['search'];
        if (query) {
            this.query = query;
            this.doSearch();
        }
    };
    SearchBoxComponent.prototype.doSearch = function () {
        this.searchSubject.next(this.query);
    };
    SearchBoxComponent.prototype.doFocus = function () {
        this.onFocus.emit(this.query);
    };
    SearchBoxComponent.prototype.focus = function () {
        this.searchBox.nativeElement.focus();
    };
    Object.defineProperty(SearchBoxComponent.prototype, "query", {
        get: function () { return this.searchBox.nativeElement.value; },
        set: function (value) { this.searchBox.nativeElement.value = value; },
        enumerable: true,
        configurable: true
    });
    return SearchBoxComponent;
}());
export { SearchBoxComponent };
